import React from 'react'
import './TermsAndConditions.css'

const TermsAndConditions = () => {
  return (
    <div className='termsandconditions'>
        <div className='tandc-container'>
            <h2>Terms and Conditions</h2>
            <h4>Terms of Use</h4>
            <h3>Welcome to Rosette Smart Life</h3>
            <div className='part1'>
                <h4>Legal Agreement</h4>
                <p>This user agreement (“Agreement”) is an agreement between you and Rosette Smart Life ( Registered as Rosette Smart Bottles LLP ) ("Rosette Smart Life" or "we" or "us" as the context requires) governing your use of Rosette Smart Life products, software and/or services with the characteristics and features as described on <a href='https://www.rosettesmartlife.com'>www.rosettesmartlife.com</a>(“Website”) and the WellWise mobile App (“App”) and your use of the Smart Bottles(referred to collectively as the "Services").By accessing/usage of the Website, the App or the Smart bottle, at your option, registering thereon and thereafter using the Services as a member or guest, you agree to be bound by this Agreement and the terms contained in it. This Agreement governs your access and use of this Website/App/Smart Device and applies to all visitors, users and others who access the Service ("Users"). If you do not agree with the terms contained in this Agreement, you are not permitted to use this Website/App/Smartdevice. Rosette Smart Life will not be liable for any consequences arising from your unauthorized use. We may revise these terms of use at any time by amending this page and the terms hereof. The revised terms of use shall be posted on the Website/App and you are expected to check this page from time to time to take notice of any changes we make, as they are binding on you. Some of these provisions may be superseded by provisions or notices published elsewhere on our Website/App/Smartdevice. All changes are effective as soon as we post them and by continuing to use the Website/App/Smart Device and avail of the Services, you agree to be bound by the revised terms and conditions of use. Your use of Website/App/Smartdevice is subject to the most current version of the terms of use posted on the Website/App/Smartdevice at the time of such use.</p>
            </div>
            <div className='part2'>
                <h4>Privacy Policy</h4>
                <p>This privacy policy (“Policy”) was last changed on April 8, 2024. We may occasionally make changes to the Policy. If we do, we shall notify either by sending you an email or sending you a notification through our mobile application (“App”). Please make sure to read such notices carefully.</p>
                <p>We hold the sincere belief that you should always know what data we collect from you, the purposes for which such data is used, and that you should have the ability to make informed decisions about what data you want to share with us.</p>
                <p>This is why we want to be fully transparent about: (i) how and why we collect, store, share and use your personal data through the various capacities in which you interact with us; and (ii) the rights that you have to determine the parameters of this interaction.</p>
                <p>While we would strongly advise you to read the Policy in full, the following summary will give you a snapshot of the salient points covered herein:</p>
                <ul>
                    <li>This Policy details the critical aspects governing your personal data relationship with ‘WellWise’ which is owned and operated by Rosette Smart Life, a company incorporated under the laws of India, and having its registered office at Moodbidri, Mangalore- 574 225, Karnataka, India;</li>
                    <li>Your personal data relationship with WellWise varies based on the capacity in which you interact with us/avail of our products and services (“Services”). You could be: (i) a visitor to <a href='https://www.rosettesmartlife.com'>www.rosettesmartlife.com</a>(“Website”) or any pages thereof (“Visitor”); or (ii) a person who has an account with WellWise to avail our Services (“Registered User”);</li>
                    <li>Based on whether you are a Visitor or Registered User, the type of data we collect and the purpose for which we use it will differ and this Policy details such variations;</li>
                    <li>This Policy applies to all the Services provided by WellWise through the Website, App or any other associated website of WellWise;</li>
                    <li>This Policy is a part of and should be read in conjunction with our Terms of Service; and</li>
                    <li>This Policy will clarify the rights available to you vis-à-vis the personal data you share with us.</li>
                </ul>
                <p>If you have any queries or concerns with this Policy, please contact our Support Center (support@rosettesmartlife.com). If you do not agree with the Policy, we would advise you to not visit/use the Website or the App.</p>
            </div>
        </div>
    </div>
  )
}

export default TermsAndConditions