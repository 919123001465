import React, { useEffect, useState } from 'react';
import BlogIMG from './blogImage.png'
import img from './Group.png';
import ProfileSVG from './Icons/profile.svg'
import CalenderSVG from './Icons/calender.svg'
import TimeSVG from './Icons/time.svg'
import { NavLink } from 'react-router-dom';
import { format } from 'date-fns';

export const BlogsPreview = ({blogData}) => {

  const [imageSrc, setImageSrc] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, 'd MMMM yyyy');
    };
  const authToken = process.env.REACT_APP_AUTH_TOKEN

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(`https://rosettebackend-one.vercel.app/api/fetchblogimage?image=${blogData.image}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);
      } catch (error) {
      }
    };

    fetchImage();
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [authToken]);
  return <div className='blogs-preview'>
        <NavLink to={`/blogs/${blogData['_id']}`}>
        <div className='card'>
          <img className='img' src={imageSrc} alt='Blog Preview' />
          <div className='content'>
            <h6 className='category'>{blogData.category}</h6>
            <h2 className='title'>{blogData.title}</h2>
            <div className='details'>
              <h6 className='author'><img className='icon' src={img} alt='time' />{blogData.author}</h6>
              <h6 className='published-date'><img className='icon' src={CalenderSVG} alt='time' />{blogData.publishedDate? formatDate(blogData.publishedDate): formatDate(blogData.createdAt)}</h6>
              <h6 className='time'><img className='icon' src={TimeSVG} alt='time' />{blogData.readTime}. to read</h6>
            </div>
            <p className='description truncate'>{blogData.shortDescription}</p>
          </div>
        </div>
        </NavLink>
      </div>
};
