import React from 'react'

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import img from "./Group.png";

const SkeletonFeaturedBlogs = () => {
    return (<div className="container">
      <div className="subheading">
        <Skeleton width={200} height={20} />
      </div>
      <div className="img">
        <Skeleton width="100%" height={200} />
      </div>
      <div className="tag">
        <Skeleton width={100} height={20} />
      </div>
      <div className="author">
        <Skeleton circle={true} width={50} height={50} />
        <Skeleton width={100} height={20} />
        <div className="stick"> </div>
        <Skeleton width={50} height={20} />
        <div className="stick"> </div>
        <Skeleton width={50} height={20} />
      </div>
      <p className="paragraph truncate">
        <Skeleton count={3} />
      </p>
    </div>
    )
    // return (<div className="container">
    //               <div className="subheading">
    //                 <p> set video playback speed with javascript</p>
    //               </div>
    //               <div className="img"></div>
    //               <div className="tag">
    //                 {" "}
    //                 <h3>Travel </h3>
    //               </div>

    //               <div className="author">
    //                 <img src={img} alt="profile" />
    //                 <h3> jesica koli</h3>
    //                 <div className="stick"> </div>{" "}
    //                 <svg
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   width="11"
    //                   height="12"
    //                   viewBox="0 0 11 12"
    //                   fill="none"
    //                 >
    //                   <path
    //                     d="M2.4 5.4H3.6V6.6H2.4V5.4ZM10.8 2.4V10.8C10.8 11.46 10.26 12 9.6 12H1.2C0.88174 12 0.576515 11.8736 0.351472 11.6485C0.126428 11.4235 0 11.1183 0 10.8L0.00599999 2.4C0.00599999 1.74 0.534 1.2 1.2 1.2H1.8V0H3V1.2H7.8V0H9V1.2H9.6C10.26 1.2 10.8 1.74 10.8 2.4ZM1.2 3.6H9.6V2.4H1.2V3.6ZM9.6 10.8V4.8H1.2V10.8H9.6ZM7.2 6.6H8.4V5.4H7.2V6.6ZM4.8 6.6H6V5.4H4.8V6.6Z"
    //                     fill="#666666" />
    //                 </svg>{" "}
    //                 <h3>02 DECEMBER 2023 </h3>
    //                 <div className="stick"> </div>
    //                 <svg
    //                   xmlns="http://www.w3.org/2000/svg"
    //                   width="12"
    //                   height="12"
    //                   viewBox="0 0 12 12"
    //                   fill="none"
    //                 >
    //                   <path
    //                     d="M6 0C2.6916 0 0 2.6916 0 6C0 9.3084 2.6916 12 6 12C9.3084 12 12 9.3084 12 6C12 2.6916 9.3084 0 6 0ZM6 10.8C3.3534 10.8 1.2 8.6466 1.2 6C1.2 3.3534 3.3534 1.2 6 1.2C8.6466 1.2 10.8 3.3534 10.8 6C10.8 8.6466 8.6466 10.8 6 10.8Z"
    //                     fill="#555555" />
    //                   <path
    //                     d="M6.60039 3H5.40039V6.2484L7.37619 8.2242L8.22459 7.3758L6.60039 5.7516V3Z"
    //                     fill="#555555" />
    //                 </svg>
    //                 <h3>3 min. to read</h3>
    //               </div>
    //               <p className="paragraph">
    //                 Did you come here for something in particular or just
    //                 general Riker-bashing?
    //               </p>
    //             </div>
    // )
}

export default SkeletonFeaturedBlogs