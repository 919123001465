import React from 'react';
import { BlogsList } from './BlogsList';
import { BlogsAside } from './BlogsAside';

export const BlogsContent = () => {
  return <section className='blogs-content container'>
    <BlogsList />
    <BlogsAside />
  </section>
};
