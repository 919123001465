import './NewBlogs.css'
import { BlogsHome } from './BlogsHome';
import { BlogsContent } from './BlogsContent';

const NewBlogs = () => {
  return (
    <div className='blogs_c'>
      <BlogsHome />
      <BlogsContent />
    </div>
  )
}

export default NewBlogs