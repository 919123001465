import React from 'react';
import FacebookSVG from './Icons/facebook.svg'
import TwitterSVG from './Icons/twitter.svg'
import InstagramSVG from './Icons/instagram.svg'
import BlogIMG from './blogImage.png'
import authorProfile from './images.png'

const TopAuthors = () => {
  return <div className='topauthors'>
    <div className="heading">
      <div className="blogsTagWrapper">
        <span className="color">Top </span>
        <span className="plain">Authors</span>
      </div>
    </div>
    <ul className='author-list'>
      <li className='card'>
        <img className='author-img' src={authorProfile} alt='Author' />
        <div className='author-content'>
          <h3>Likith Kumar</h3>
          {/* <h5>Fashion designer, Blogger, activist</h5> */}
          <ul className='social-links'>
            <li className='social'><img src={FacebookSVG} alt='Facebook link' /></li>
            <li className='social'><img src={TwitterSVG} alt='Twitter link' /></li>
            <li className='social'><img src={InstagramSVG} alt='Instagram link' /></li>
          </ul>
        </div>
      </li>
      <li className='card'>
        <img className='author-img' src={authorProfile} alt='Author' />
        <div className='author-content'>
          <h3>Samiksha N</h3>
          {/* <h5>Fashion designer, Blogger, activist</h5> */}
          <ul className='social-links'>
            <li className='social'><img src={FacebookSVG} alt='Facebook link' /></li>
            <li className='social'><img src={TwitterSVG} alt='Twitter link' /></li>
            <li className='social'><img src={InstagramSVG} alt='Instagram link' /></li>
          </ul>
        </div>
      </li>
      <li className='card'>
        <img className='author-img' src={authorProfile} alt='Author' />
        <div className='author-content'>
          <h3>Scarlet Dsouza</h3>
          {/* <h5>Fashion designer, Blogger, activist</h5> */}
          <ul className='social-links'>
            <li className='social'><img src={FacebookSVG} alt='Facebook link' /></li>
            <li className='social'><img src={TwitterSVG} alt='Twitter link' /></li>
            <li className='social'><img src={InstagramSVG} alt='Instagram link' /></li>
          </ul>
        </div>
      </li>
      {/* <li className='card'>
        <img className='author-img' src={BlogIMG} alt='Author' />
        <div className='author-content'>
          <h3>Jenny Kia</h3>
          <h5>Fashion designer, Blogger, activist</h5>
          <ul className='social-links'>
            <li className='social'><img src={FacebookSVG} alt='Facebook link' /></li>
            <li className='social'><img src={TwitterSVG} alt='Twitter link' /></li>
            <li className='social'><img src={InstagramSVG} alt='Instagram link' /></li>
          </ul>
        </div>
      </li> */}
    </ul>
  </div>
};
const Categories = () => {
  return <div className='categories'>
    <div className="heading">
      <div className="blogsTagWrapper">
        <span className="color">Categories</span>
        <span className="plain"></span>
      </div>
    </div>
    <ul className='cate-list'>
      <li>
        <h6>Lifestyle</h6>
        <h6>09</h6>
      </li>
      <li>
        <h6>Hydration</h6>
        <h6>05</h6>
      </li>
      <li>
        <h6>food</h6>
        <h6>09</h6>
      </li>
      <li>
        <h6>healthcare</h6>
        <h6>10</h6>
      </li>
      <li>
        <h6>Sleep</h6>
        <h6>03</h6>
      </li>
    </ul>
  </div>
};
const Update = () => {
  return <div className='update'>
    <div className="heading">
      <div className="blogsTagWrapper">
        <span className="color">Today's</span>
        <span className="plain">Update</span>
      </div>
    </div>
    <ul className='update-list'>
      <li>
        <h4>14</h4>
        <h6>New posts</h6>
      </li>
      <li>
        <h4>480</h4>
        <h6>total visitors</h6>
      </li>
      <li>
        <h4>29</h4>
        <h6>New subscribers</h6>
      </li>
      <li>
        <h4>138</h4>
        <h6>blog read</h6>
      </li>
    </ul>
  </div>
};

export const BlogsAside = () => {
  return <section className='side-content'>
      <TopAuthors />
      <Categories />
      <Update />
  </section>
};
