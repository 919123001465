import React, { useEffect, useState } from 'react';
import { BlogsPreview } from './BlogsPreview';
import previousSVG from './Icons/previous.svg';
import nextSVG from './Icons/next.svg';
import { SkeletonBlogPreview } from './SkeletonBlogPreview';

export const BlogsList = () => {
  const [totalPages, setTotalPages] = useState(0)
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [pageNo, setPageNo] = useState(1)

  const previousPage = () => {
    if(pageNo > 1){
      setLoading(true);
      setPageNo(pageNo - 1)
      }
    }
    const nextPage = () => {
    if(pageNo < totalPages){
      setLoading(true);
      setPageNo(pageNo + 1)
    }
  }
  const authToken = process.env.REACT_APP_AUTH_TOKEN;
  const getBlogs = async () => {
    try {
      const response = await fetch(`https://rosettebackend-one.vercel.app/api/fetchblogs/${pageNo}/6`, {
        method: 'GET',
        headers: {
          'token': authToken,
          'Content-Type': 'application/json'
        }
      });
      const data = await response.json();
      setBlogs(data.data);
      setTotalPages(Math.ceil(data.totalBlogs/6));
      setLoading(false)
      // console.log(data)
    } catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    getBlogs()
  }, [pageNo])
  return <section className='blogs-list'>
    <div className="heading">
      <div className="blogsTagWrapper">
        <span className="color">Recently </span>
        <span className="plain">Posted</span>
      </div>
    </div>
    <ul className='list'>

      {loading ? 
      Array.from({ length: 6 }).map(() => {
        return <SkeletonBlogPreview />
      })
      : (
        totalPages === 0 ?
          <div>No Blogs</div>

        : blogs.map((ele, index) => {
          return <BlogsPreview blogData={ele} key={ele['_id']} />
        })
      )
      }
    </ul>
    {!(totalPages === 0) && <div className='pagination'>
      <button onClick={previousPage} className='page-btn previous'>
        <i><img src={previousSVG} alt='Previous' /></i>
        <p>Prev</p>
      </button>
      <button className='page-btn'>{pageNo}</button>
      <button onClick={nextPage} className='page-btn next'>
        <p>Next</p>
        <i><img src={nextSVG} alt='Next' /></i>
      </button>
    </div>}
  </section>
};
