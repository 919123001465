import React, { useEffect, useRef, useState } from 'react'
import './../ProductsServices/ProductsServices.css'
import './ProductsAndServices.css'
import './NewProductsAndServices.css'
import BGBase from './../../Images/ProductsAndServices/bg-base.svg'
import WellwiseMobile from './../../Images/ProductsServices/wellwise-mobile.svg'
import Clock from './../../Images/ProductsServices/clock.svg'
import More from './../../Images/ProductsServices/more.svg'
import Nutrition from './../../Images/ProductsServices/nutrition.svg'
import Btn1Mobile from './../../Images/ProductsAndServices/btn1_mobile.svg'
import Btn2Mobile from './../../Images/ProductsAndServices/btn2_mobile.svg'
import Btn1Bottle from './../../Images/ProductsAndServices/btn1_bottle.svg'
import Btn2Bottle from './../../Images/ProductsAndServices/btn2_bottle.svg'
import Product1 from './../../Images/ProductsAndServices/product-1.svg'
import Product2 from './../../Images/ProductsAndServices/product-2.svg'
import Drop from './../../Images/ProductsServices/drop.svg'
import Wellwise from './../../Images/ProductsServices/wellwise.svg'
import WellwiseHead from './../../Images/ProductsAndServices/Wellwise.svg'
import Cold from './../../Images/ProductsAndServices/cold_img.svg'
import SmartFlask from './../../Images/ProductsAndServices/smart_flask.svg'
import Left from './../../Images/ProductsServices/left.svg'
import Right from './../../Images/ProductsServices/right.svg'
import waterIntake from './../../Images/ProductsAndServices/smartFlask/newImages/1.png'
import tenHoursHot from './../../Images/ProductsAndServices/smartFlask/newImages/2.png'
import smartConnect from './../../Images/ProductsAndServices/smartFlask/newImages/3.png'
import LEDReminder from './../../Images/ProductsAndServices/smartFlask/newImages/4.png'
import twentyHoursCold from './../../Images/ProductsAndServices/smartFlask/newImages/5.png'
import SmartFlaskLogo from './../../Images/ProductsAndServices/smartFlask/smartFlaskLogo.svg'

const ProductsAndServices = () => {
    const smartFlaskFeatures = [
        {
            name : 'Water Intake Monitoring',
            image : waterIntake
        },
        {
            name : '10 hours hot',
            image : tenHoursHot
        },
        {
            name : 'Smart Phone Connectivity',
            image : smartConnect
        },
        {
            name : 'Led Reminders',
            image : LEDReminder
        },
        {
            name : '20 hours cold',
            image : twentyHoursCold
        },
    ]
    const images = [
        Product1,
        Product2,
      ];
    const buttonImagesActive = [
        Btn1Mobile,
        Btn1Bottle,
      ];
    const buttonImages = [
        Btn2Mobile,
        Btn2Bottle,
      ];

    const [productKey, setProductKey] = useState(0)
    const imgList = useRef();
    const h3List = useRef();
    const handleScroll = (e, index) => {
        const imageWidth = imgList.current.offsetWidth;
        imgList.current.scrollTo({
            left: imageWidth * index,
            behavior: 'smooth',
        });
    }
    useEffect(() => {
        const handleManualScroll = () => {
          if (imgList.current) {
            const scrollLeft = imgList.current.scrollLeft;
            const imageWidth = imgList.current.offsetWidth;
            const newIndex = Math.round(scrollLeft / imageWidth);
            setProductKey(newIndex);
            const h3Width = h3List.current.offsetWidth;
            h3List.current.scrollTo({
                left: h3Width * newIndex,
                behavior: 'auto',
            });
          }
        };
    
        const slider = imgList.current;
        slider.addEventListener('scroll', handleManualScroll);
        
    
        return () => {
          slider.removeEventListener('scroll', handleManualScroll);
        };
      }, []);
  return (
    <div className='productsandservices'>
        <section className='products-home'>
            <div className='products-home-c container'>
                <h2 className='product-h2'>Services here are made with love to keep you on your track of Wellness and Productive Lifestyle.</h2>
                <div className='product-carousel'>
                    <div className='product-showcase'>
                        <div className='product' ref={imgList}>
                            {images.map((image, index) => {
                                return <img className='productimg' src={image} alt={'Product '+index} />
                            })}
                        </div>
                        <img className='base-bg' src={BGBase} alt='base-bg' />
                    </div>
                </div>
                <div className='product-content'>
                    <div className='product-options'>
                        {images.map((image, index) => {
                        return  <div onClick={(e) => handleScroll(e, index)} className={productKey === index ?'option mobile active':'option mobile'}>
                                    {productKey === index ? <img className='mobile' src={buttonImagesActive[index]} alt='mobile svg' /> : <img className='mobile' src={buttonImages[index]} alt='mobile svg' />}
                                </div>
                        })}
                    </div>
                    <div ref={h3List} className='h3' onDrag={(e) => {
                        e.preventDefault()
                    }}>
                        <div><h3>Wellwise - An Health Mangament Application</h3></div>
                        <div><h3>Smart Water Bottle</h3></div>
                    </div>
                    <div className='buttons-options'>
                        <button className='product-btn'>{!productKey? "Download Now": "Book Now"}</button>
                        <button className='product-btn'>Know More</button>
                    </div>
                </div>
                </div>
        </section>
        <section className='wellwise container'>
            <div className='wellwise-header'>
                <img src={WellwiseHead} alt='Wellwise' />
                <div>
                    <div className='wellwise-heading'>
                        <div className='wellwise-heading-logo'><img src={Wellwise} alt='Wellwise' /></div>
                        <h2>Wellwise</h2>
                    </div>
                    <h3 className='wellwise-heading-2'>An Intelligent Health & Lifestyle Mobile Application </h3>
                </div>
            </div>
            <div className='wellwise-container'>
                <ul className='wellwise-list'>
                    <li className='card'>
                        <img src={Drop} alt='Hydration tracking' />
                        <h5>Hydration tracking</h5>
                    </li>
                    <li className='card'>
                        <img src={Nutrition} alt='Nutrition tracking' />
                        <h5>Nutrition tracking</h5>
                    </li>
                    <li className='card'>
                        <img src={Clock} alt='Sleep tracking ' />
                        <h5>Sleep tracking</h5>
                    </li>
                    <li className='card'>
                        <img src={More} alt='Many more' />
                        <h5>Many more</h5>
                    </li>
                </ul>
                <div className='wellwise-mobile-image'>
                    <img src={WellwiseMobile} alt='Wellwise Mobile View'></img>
                </div>
            </div>
        </section>
        <section className='salient container'>
          <h3>Salient Features</h3>
          <div className='feature-container'>
            <div className='feature-img-c'>
              <img src={Left} alt='Salient Features' />
            </div>
            <ul className='feature-list'>
              <li>Real time Analysis</li>
              <li>Artificial intelligence integration</li>
              <li>Multi Smart Device Connection</li>
              <li>Community care</li>
              <li>Complete heath and Lifestyle management in Single platform</li>
            </ul>
            <div className='feature-img-c'>
              <img src={Right} alt='Salient Features' />
            </div>
          </div>
        </section>
        <section className='smartflask-heading container'>
            <div className='smartflask-heading-logo'><img src={SmartFlaskLogo} alt='Smart Flask Logo' /></div>
            <h2>Smart Flask</h2>
        </section>
        <section className='smartflask container'>
            <div className='flask_img_c'>
                <img src={SmartFlask} alt='Smart Flask' />
            </div>
            <div className='circle_c'>
                <ul className='circle'>
                    {smartFlaskFeatures.map((ele, index) => {
                        return <li style={{'--i':index}}>
                                    <img src={ele.image} alt='Cold' />
                                    <p>{ele.name}</p>
                                </li>
                    })}
                    {smartFlaskFeatures.map((ele, index) => {
                        return <li className='clone' style={{'--i':index+5}}>
                                    <img src={ele.image} alt='Cold' />
                                    <p>{ele.name}</p>
                                </li>
                    })}
                </ul>
            </div>
            <div className='launch_soon_c'>
                <h3>Launching Soon...</h3>
                <div className='button-list'>
                    <button>Book Now</button>
                    <button>Give Feedback</button>
                </div>
            </div>
        </section>
    </div>
  )
}

export default ProductsAndServices