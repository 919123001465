import React, { useEffect, useState } from 'react';
import AboutUs from './../../Images/NavBar/AboutUs.svg'
import Arrow from './../../Images/NavBar/Arrow.svg'
import Blog from './../../Images/NavBar/Blog.svg'
import BoxIcon from './../../Images/NavBar/BoxIcon.svg'
import Cart from './../../Images/NavBar/Cart.svg'
import ContactUs from './../../Images/NavBar/ContactUs.svg'
import Logout from './../../Images/NavBar/Logout.svg'
import ShopIcon from './../../Images/NavBar/ShopIcon.svg'
import StarIcon from './../../Images/NavBar/StarIcon.svg'
import Workshop from './../../Images/NavBar/Workshop.svg'

import './NavBar.css'; // Import your CSS file
import logo1 from './../../Images/logo/Rosette_Icon_with_black_text.svg';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggleDropdown = (menu) => {
    setDropdownOpen(dropdownOpen === menu ? null : menu);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  // Set up the resize listener
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <Link to="/">
            <div className="logo-c">
              <div className="logo-container">
                <img src={logo1} alt="Rosette Smart Bottles" />
              </div>
            </div>
          </Link>
          
         {/* Hamburger and Close Button */}
         <button className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? '✕' : '☰'}
        </button>
        {isMobile ? (
      <ul
      className={`nav-link-container ${menuOpen ? 'nav-link-container-open' : ''}`}
      style={{
        display: menuOpen ? 'flex' : 'none',
        flexDirection: 'column',
        padding: '20px',
        marginTop: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'absolute',
        top: '60px',
        left: '0',
        right: '0',
        backgroundColor: 'white',
        zIndex: 1000,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
        width: '90%',
        maxWidth: '300px',
        transition: 'all 0.3s ease',
        overflowY: 'auto',
        maxHeight: '80vh', // limit height to fit the screen
      }}
    >
      <div className="dropdown-title">Menu </div>
      {/* <hr/> */}
      {/* Product Dropdown */}
      <li>
        <button className="dropdown-btn" onClick={() => toggleDropdown('products')}>
          Products <img src={Arrow} className="arrow-icon" alt="arrow icon" />
        </button>
        {dropdownOpen === 'products' && (
          <div className="dropdown-menu">
            {/* <div className="dropdown-title">Products</div> */}
            {/* <hr /> */}
            <Link to="/product-details" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <div style= {{display: "flex", flexDirection:"row"}}>
                <img src={BoxIcon} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item"> Smart Bottle</div>
              </div>
              </div>
            </Link>
            <Link to="/product-details" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
              <div style= {{display: "flex", flexDirection:"row"}}>
                <img src={StarIcon} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Facus Monitor</div>
              </div>
              </div>
            </Link>
            {/* <hr /> */}
          </div>
        )}
      </li>
      
      {/* Services Dropdown */}
      <li>
        <button className="dropdown-btn" onClick={() => toggleDropdown('services')}>
          Services <img src={Arrow} className="arrow-icon" alt="arrow icon" />
        </button>
        {dropdownOpen === 'services' && (
          <div className="dropdown-menu">
            {/* <div className="dropdown-title">Mobile Application</div> */}
            {/* <hr /> */}
            <Link to="/product-details" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
              <div style= {{display: "flex", flexDirection:"row"}}>
                <img src={BoxIcon} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Wellwise</div>
              </div>
              </div>
            </Link>
            <Link to="/product-details" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
              <div style= {{display: "flex", flexDirection:"row"}}>
                <img src={StarIcon} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Ayush</div>
              </div>
              </div>
            </Link>
            {/* <hr /> */}
          </div>
        )}
      </li>
  
      {/* More Dropdown */}
      <li>
        <button className="dropdown-btn" onClick={() => toggleDropdown('more')}>
          More <img src={Arrow} className="arrow-icon" alt="arrow icon" />
        </button>
        {dropdownOpen === 'more' && (
          <div className="dropdown-menu">
            {/* <div className="dropdown-title">Explore More</div> */}
            {/* <hr /> */}
            <Link to="/about-us" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
              <div style= {{display: "flex", flexDirection:"row"}}>
                <img src={AboutUs} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">About Us</div>
              </div>
              </div>
            </Link>
            <Link to="/blogs" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
              <div style= {{display: "flex", flexDirection:"row"}}>
                <img src={Blog} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Wellness Blog</div>
              </div>
              </div>
            </Link>
            <Link to="/blogs" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
              <div style= {{display: "flex", flexDirection:"row"}}>
                <img src={Workshop} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Wellness Workshop</div>
              </div>
              </div>
            </Link>
            <Link to="/contact-us" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
              <div style= {{display: "flex", flexDirection:"row"}}>
                <img src={ContactUs} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Contact Us</div>
              </div>
              </div>
            </Link>
            {/* <hr /> */}
          </div>
        )}
      </li>
  
      {/* Shop Dropdown */}
      <li>
        <button className="dropdown-btn" onClick={() => toggleDropdown('shop')}>
          Shop <img src={ShopIcon} className="arrow-icon" alt="arrow icon" />
        </button>
        {dropdownOpen === 'shop' && (
          <div className="dropdown-menu">
            {/* <div className="dropdown-title">Account</div> */}
            {/* <hr /> */}
            <Link to="/about-us" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
              <div style= {{display: "flex", flexDirection:"row"}}>
                <img src={Cart} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Shopping Cart</div>
              </div>
              </div>
            </Link>
            <Link to="/contact-us" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
              <div style= {{display: "flex", flexDirection:"row"}}>
                <img src={Logout} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Logout</div>
              </div>
              </div>
            </Link>
            {/* <hr /> */}
          </div>
        )}
      </li>
            <button className="login">Login</button>
          </ul>
      ):<ul className={`nav-link-container ${menuOpen ? 'nav-link-container-open' : ''}`}  >
      <li>
        <button className="dropdown-btn" style={{fontSize:"1.25rem"}} onClick={() => toggleDropdown('products')}>
          Products <img src={Arrow} className="arrow-icon" alt="arrow icon" />
        </button>

        {dropdownOpen === 'products' && (
          <div className="dropdown-menu">
            <div className="dropdown-title">Products</div>
            <hr />
            <Link to="/product-details" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <img src={BoxIcon} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Smart Bottle</div>
              </div>
            </Link>

            <Link to="/product-details" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <img src={StarIcon} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Facus Monitor</div>
              </div>
            </Link>
            <hr />
          </div>
        )}
      </li>
      <li>
        <button className="dropdown-btn" style={{fontSize:"1.25rem"}} onClick={() => toggleDropdown('services')}>
          Services <img src={Arrow} className="arrow-icon" alt="arrow icon" />
        </button>
        {dropdownOpen === 'services' && (
          <div className="dropdown-menu">
            <div className="dropdown-title">Mobile Application</div>
            <hr />
            <Link to="/product-details" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <img src={BoxIcon} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Wellwise</div>
              </div>
            </Link>
            <Link to="/product-details" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <img src={StarIcon} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Ayush</div>
              </div>
            </Link>
            <hr />
          </div>
        )}
      </li>
      <li>
        <button className="dropdown-btn" style={{fontSize:"1.25rem"}} onClick={() => toggleDropdown('more')}>
          More <img src={Arrow} className="arrow-icon" alt="arrow icon" />
        </button>
        {dropdownOpen === 'more' && (
          <div className="dropdown-menu">
            <div className="dropdown-title">Explore More</div>
            <hr />
            <Link to="/about-us" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <img src={AboutUs} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">About Us</div>
              </div>
            </Link>
            <Link to="/blogs" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <img src={Blog} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Wellness Blog</div>
              </div>
            </Link>
            <Link to="/blogs" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <img src={Workshop} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Wellness Workshop</div>
              </div>
            </Link>
            <Link to="/contact-us" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <img src={ContactUs} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Contact Us</div>
              </div>
            </Link>
            <hr />
          </div>
        )}
      </li>
      <li>
        <button className="dropdown-btn" style={{fontSize:"1.25rem"}} onClick={() => toggleDropdown('shop')}>
          Shop <img src={ShopIcon} className="arrow-icon" alt="arrow icon" />
        </button>
        {dropdownOpen === 'shop' && (
          <div className="dropdown-menu">
            <div className="dropdown-title">Account</div>
            <hr />
            <Link to="/about-us" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <img src={Cart} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Shopping Cart</div>
              </div>
            </Link>
            <Link to="/contact-us" onClick={() => setDropdownOpen(null)}>
              <div className="dropdown-item-container">
                <img src={Logout} className="dropdown-item-icon" alt="icon" />
                <div className="dropdown-item">Logout</div>
              </div>
            </Link>
            <hr />
          </div>
        )}
      </li>
      <button className="login">Login</button>
    </ul> }
        </div>
      </nav>
    </>
  );
};

export default NavBar;






// import React, { useRef } from 'react'


// import './NavBar.css'
// import logo from './../../Images/logo/logo.svg'
// import logo2 from './../../Images/logo/Rosette Final Logo-01 (2).png'
// import logo1 from './../../Images/logo/Rosette_Icon_with_black_text.svg'
// import { Link, Outlet } from 'react-router-dom';


// const NavBar = (props) => {
//   const mobileNav = useRef();
//   const navIcon = useRef();
//   const mobileNavToggle = () => {
//     mobileNav.current.toggleAttribute('aria-expanded')
//     // e.target.toggleAttribute('aria-expanded')
//     navIcon.current.toggleAttribute('aria-expanded')
//     props.toggleOverlay()
//   }
//   return (
//     <>
//       <nav className='navbar'>
//         <div className='container'>
//           <Link to={'/'}>
//             <div className='logo-c'>
//               <div className='logo-container'>
//                 <img src={logo1} alt='Rosette Smart Bottles' />
//               </div>
//               <h1 className='logo-name'>
//                 {/* Rosette Smart Life  */}
//               </h1>
//             </div>
//           </Link>
//           <ul ref={mobileNav} className='nav-link-container'>
//             <Link onClick={() => mobileNavToggle()} to='/'><li>Home</li></Link>
//             <Link onClick={() => mobileNavToggle()} to='/about-us'><li>About Us</li></Link>
//             <Link onClick={() => mobileNavToggle()} to='/product-details'><li>Product & Services</li></Link>
//             <Link onClick={() => mobileNavToggle()} to='/blogs'><li>Blogs & Tips</li></Link>
//             <Link onClick={() => mobileNavToggle()} to='/contact-us'><li>Contact Us</li></Link>
//           </ul>
//           <i ref={navIcon} onClick={() => mobileNavToggle()} className='icons menu mobile'></i>
//         </div>
//       </nav>
//       <Outlet />
//     </>
//   )
// }

// export default NavBar